<template>
  <form @submit.prevent="submit" id="form-field">
    <page :data="pageData" :is-child="_isChild">
      <template #cmslefttype(form)="{ data: d }">
          <all-field :data="d.Data" @error="setErrors"></all-field>
          <div class="flex w-full">
            <!-- <b-button v-if="isMd && (typeof d.CloseButton !== 'undefined' ? d.CloseButton : true)" class="mr-2" @click="$router.back(), setField()">{{d.CloseButton || "Close"}}</b-button> -->
            <b-button class="w-full md:w-auto" type="is-primary" native-type="submit" :disabled="hasError">{{d.SubmitButton || "Save"}}</b-button>
          </div>
      </template>
    </page>
  </form>
</template>

<script>
import BaseFormPage from './BaseFormPage'
import CMSPage from './CMSPage'
export default {
  extends: CMSPage,
  mixins: [BaseFormPage],
  components: {
    AllField: () => import('@/components/form/AllField')
  },
  methods: {
    async submit () {
      this.$store.commit('service/SET_CONDITION_NOTIF', true)
      const loading = this.$buefy.loading.open()
      try {
        const form = document.getElementById('form-field')
        let data
        if (form.querySelector('input[type="file"]')) {
          data = new FormData(form)
        } else {
          data = this.getData('form-field')
        }
        await this.$baseApi.post(this.apiPath, data)
        if (this.config.RedirectOnSuccess) this.$router.push(this.config.RedirectOnSuccess)
        else this.$router.back()
      } catch (err) {
        this.handleSubmitError(err)
      } finally {
        loading.close()
      }
    },
    setField () {
      this.$store.commit('service/SET_CONDITION_NOTIF', false)
    }
  },
  computed: {
    config () {
      const config = this.response.Config || {}
      return {
        ...config,
        Page: {
          Padding: true,
          ...config.Page
        }
      }
    }
  }
}
</script>

<style>

</style>
